<template>
  <v-container class="app-page">
    <v-card class="overflow-hidden text-center">
      <h1 class="titulo">Legajo</h1>

      <!--<v-container style="max-width:780px">
        <v-row class="filtros-legajo">
          <v-col class="d-flex" cols="12" sm="12">
            <v-text-field
              class="buscar"
              label="buscar"
              prepend-inner-icon="mdi-magnify"
              append-icon="mdi-filter"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex flex-row align-start pa-0" cols="12" sm="5">
            <span class="select-control">Desde</span>
            <v-select 
              class="select" 
              :clearable="true"
              v-model="filters.desde" 
              :items="filters.fechas" 
              label="Todos" 
              solo
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="d-flex flex-row align-start pa-0" cols="12" sm="5">
            <span class="select-control">Tipo</span>
            <v-select 
              class="select" 
              :clearable="true"
              v-model="filters.idTipoLegajo" 
              :items="filters.tipos_legajo" 
              item-text="Nombre"
              item-value="id_TMLegajo"
              label="Todos" 
              solo
            ></v-select>
          </v-col>
        </v-row>
      </v-container>-->

      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        class="app-table"
        :hide-default-footer="true"
        mobile-breakpoint="0"
      >
        <!--<template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  light
                  ripple="false"
                  class="btn-exportar text-lowercase mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  exportar
                  <v-img class="ml-3" right src="@/assets/app-page/icon-exportar.svg"></v-img>
                </v-btn>
              </template>
            </v-menu>
          </v-toolbar>
        </template>-->

        <template v-slot:item.Fecha="{ item }">{{ formatDate(item.Fecha) }}</template>

        <template v-slot:item.ver="{ item }">
          <v-btn class="pa-4" x-small icon @click="verMemo(item)">
            <v-img src="@/assets/app-page/icon-ver-ojo.svg"></v-img>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <span>Información</span>
          <v-spacer></v-spacer>
          <v-btn 
              @click="downloadAttach()"
              icon 
              x-small
              color="default"
            >
            <v-img src="@/assets/app-page/icon-ver-ojo.svg"></v-img>
          </v-btn>
        </v-card-title>

        <v-card-text>{{ dialogContent }}</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getLegajoData, getMedia } from "@/api/core";
import { mapGetters } from "vuex";
import { gnsDate } from "@/filters/index";

export default {
  name: "legajo",
  data: () => ({
    loading: false,

    items: [],

    headers: [
      { text: "Nro", value: "id_Persona" },
      { text: "Mes", value: "Fecha" },
      { text: "Tipo", value: "Nombre" },
      { text: "de valor", value: "StrDeValor" },
      { text: "a valor", value: "StrAValor" },
      { text: "Ver", value: "ver", sortable: false, align: "center" },
    ],

    dialog: false,
    dialogContent: null,
    dialogItem: null,

    filters: {
      tipos_legajo: [],
      fechas: [],
      idTipoLegajo: null,
      desde: null,
    },
  }),

  computed: {
    ...mapGetters(["companies", "user"]),
  },

  mounted() {
    this.$store.commit("setNavigationDrawer", false);
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Legajo");
    this.$store.commit("stopProcessing");
    this.getData();
  },

  filters: {
    gnsDate: function (value) {
      return gnsDate(value);
    },
  },

  methods: {
    formatDate(datetime) {
      if (!datetime) return null;

      const date = datetime.split(" ")[0];
      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year}`;
    },

    verMemo(item) {
      this.dialogItem = item
      if(item.Memo) {
        this.dialog = true;
        this.dialogContent = item.Memo;
      } else {
        this.downloadAttach()
      }
    },

    async downloadAttach() {
      // let url = this.user.host + '/' + this.user.company_abrev + '/ArchVinc/' + this.user.id_persona + '/' + this.dialogItem.ArchVinc
      let q = '/' + this.user.company_abrev + '/ArchVinc/' + this.user.id_persona + '/' + this.dialogItem.ArchVinc
      const data = await getMedia(q)
      let url = data.url
      window.open(url, "_blank")

      /* binary data received from server response */;
      // const blob = new Blob([data], { type: 'application/pdf' });
      // const url = URL.createObjectURL(blob);
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'file.pdf');
      // document.body.appendChild(link);
      // link.click();
    },

    async getData() {
      this.loading = true;

      const data = await getLegajoData();

      if (data) {
        this.items = this.items.concat(data.records);
        this.filters.tipos_legajo = data.tipos;
        this.filters.fechas = this.items.map((item) => {
          return item.Fecha.split(" ")[0].replace(/-/g, "/");
        });
      }

      this.loading = false;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>

<style>
.filtros-legajo .buscar .v-input__slot {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 19px;
  /* or 100% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #747474;
}
.filtros-legajo .buscar .v-icon {
  color: rgba(0, 0, 0, 0.15) !important;
}
.filtros-legajo .buscar .v-input__slot:before {
  border-color: rgba(0, 0, 0, 0.15) !important;
}
.filtros-legajo .select .v-input__slot {
  box-shadow: 0px 8px 20px rgba(29, 14, 40, 0.1) !important;
  border-radius: 55px;
}

.select-control {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 49px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #747474;
  padding-right: 2rem;
}
</style>